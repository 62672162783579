import styled from "styled-components";

type mainProps = {
  horizontalCenter?: boolean;
  verticalCenter?: boolean;
  half?: boolean;
  oneThird?: boolean;
};

export const Main = styled.main<mainProps>`
  ${({ horizontalCenter }) => horizontalCenter && ` margin: 0 auto; `}
  
  ${({ verticalCenter }) =>
    verticalCenter &&
    `
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  `}
  
  ${({ theme, half }) => half && theme.breakPoints?.lg && ` width: 50%; `}
  
  ${({ theme, oneThird }) => oneThird && theme.breakPoints?.lg && ` width: 75%; `}
`;
