import styled from "styled-components";

export const Header = styled.header`
  max-width: ${({ theme }): string =>
    theme.breakPoints?.xs ? "100%" : theme.breakPoints?.md ? "75%" : "50%"};
  margin: 0 auto calc(${({ theme }): string => theme.margin} * 2);
  text-align: center;

  > h2 {
    font-size: 16px;
  }
`;
