import styled from "styled-components";

type figureProps = {
  alternate?: boolean;
};

export const Figure = styled.figure<figureProps>`
  width: 100%;
  display: flex;
  flex-flow: ${({ theme }) => (theme.breakPoints?.xs ? "column-reverse" : "row")};
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.margin};

  ${({ alternate, theme }) =>
    alternate &&
    `
    &:nth-child(even) {
      flex-flow: ${theme.breakPoints?.xs ? "column-reverse" : "row-reverse"};
      text-align: ${theme.breakPoints?.xs ? "inherit" : "right"};
    }
  `}

  .gatsby-image-wrapper {
    display: block;
    width: ${({ theme }) => (theme.breakPoints?.xs ? "100%" : "60%")};
  }
`;

export const Figcaption = styled.figcaption`
  width: ${({ theme }) => (theme.breakPoints?.xs ? "100%" : theme.breakPoints?.md ? "35%" : "27.5%")};
  display: flex;
  flex-flow: column;
  align-self: center;

  > h5 {
    margin-bottom: calc(${({ theme }) => theme.margin} / 4);
  }
`;
